<template>
  <LayoutAuthForm>
    <VueForm ref="form" :bodyPadding="['0', '20']">
      <VueText sizeLevel="7" weightLevel="1" style="lineHeight: 1.5"
        >Güvenlik sebebiyle şifrenizi güncellemeniz gerekmektedir. Lütfen şifrenizi kimseyle
        paylaşmayınız.</VueText
      >
      <VueSpacer level="20" />
      <VueInput
        :type="passwordType"
        name="password"
        id="password"
        validatorName="Şifre"
        label="Yeni Şifre"
        v-model="formModel.password"
        :messageOnTop="true"
        borderType="left-bordered"
        ref="password"
        validationRules="required|max:20|min:8|atLeastTwoChar"
        :validState="true"
      >
        <VueButton
          :contentAlignment="constants.flexAlignment.center"
          :ghost="true"
          as="a"
          iconName="IconEye"
          :iconSize="{ width: getIcon.width, height: getIcon.height }"
          @click="togglePassword"
        ></VueButton>
      </VueInput>
      <VueSpacer level="20" />
      <VueInput
        :type="passwordType"
        name="passwordRepeat"
        id="passwordRepeat"
        validatorName="Şifre"
        placeholder="Yeni Şifreyi Tekrar Giriniz"
        v-model="formModel.passwordRepeat"
        :messageOnTop="true"
        borderType="left-bordered"
        validationRules="required|max:20|min:8|atLeastTwoChar|confirmed:password"
        :validState="true"
      >
        <VueButton
          :contentAlignment="constants.flexAlignment.center"
          :ghost="true"
          as="a"
          iconName="IconEye"
          :iconSize="{ width: getIcon.width, height: getIcon.height }"
          @click="togglePassword"
        ></VueButton>
      </VueInput>
      <div slot="footer">
        <BrandButton
          :disabled="isDisabled"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          @click.prevent="submitPassword"
          >GİRİŞ</BrandButton
        >
      </div>
    </VueForm>
  </LayoutAuthForm>
</template>

<script>
import { Account } from '@/services/Api/index';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import LayoutAuthForm from '@/views/layouts/LayoutAuthForm.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import { clearAppData } from '@/mixins/storageUtils.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';

import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';

export default {
  name: 'ExpiredPassword',
  components: { LayoutAuthForm, VueText, VueSpacer, VueForm, VueInput, VueButton, BrandButton },
  data: () => {
    return {
      isDisabled: true,
      formModel: {
        password: '',
        passwordRepeat: '',
      },
      passwordType: 'password',
      submitted: false,
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
    getIcon() {
      return ICON_VARIABLES.eye;
    },
    minValue() {
      return this.formModel.password.length >= 8;
    },
    atLeast() {
      let list = 0;
      if (this.formModel.password.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g)) {
        list++;
      }
      if (this.formModel.password.match(/[0-9]/g)) {
        list++;
      }
      if (this.formModel.password.match(/[a-z]/g)) {
        list++;
      }
      if (this.formModel.password.match(/[A-Z]/g)) {
        list++;
      }
      if (list > 1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    togglePassword() {
      this.passwordType === 'password'
        ? (this.passwordType = 'text')
        : (this.passwordType = 'password');
    },
    async submitPassword() {
      this.submitted = true;

      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        Account.updateExpiredPassword(this.formModel.password)
          .then(res => {
            if (res.data.Data.success && res.status) {
              clearAppData(
                'logout',
                `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectLoginType.path}`,
              );
              return;
            }
            if (res.data.Error.Code === '500') {
              const errModalConfig = {
                isModalOpened: true,
                modalText: 'Şifre geçersiz lütfen tekrar deneyiniz.',
                noTitle: true,
              };
              this.$store.dispatch('app/setErrorModalConfig', errModalConfig);
            }
          })
          .catch(err => {
            console.log(err);
            const errModalConfig = {
              isModalOpened: true,
              modalText: 'Şifre geçersiz lütfen tekrar deneyiniz.',
              noTitle: true,
            };
            this.$store.dispatch('app/setErrorModalConfig', errModalConfig);
          });
      }
    },
  },
  created() {
    if (!this.$route.params.isAuth) {
      this.$router.push(
        `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectLoginType.path}`,
      );
    }
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        if (
          this.atLeast &&
          this.minValue &&
          this.formModel.password === this.formModel.passwordRepeat
        ) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  line-height: 1.5;
}
</style>
